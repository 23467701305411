import './ui-elements'
import './form'
import './popup'
import './animation'

$(function () {

Fancybox.bind("[data-fancybox]", {});
console.log(Fancybox);

if($("div").is(".js-product-init")){ 
  var product = new Swiper('.js-product-init', {
    spaceBetween: 20,		
    slidesPerView: 4,
    loop: true,
    navigation: {
      nextEl: '.js-product__next',
      prevEl: '.js-product__prev',
    }, 
    breakpoints: {
      992: {
        spaceBetween: 20,		
        slidesPerView: 3,
      },
      768: {
        spaceBetween: 20,		
        slidesPerView: 2,
      },
      580: {
        spaceBetween: 20,		
        slidesPerView: 1, 
      },  
    },
    // scrollbar: {
    //   el: '.js-main-banner-scrollbar',
    //   draggable: true,
    //   snapOnRelease: true,
    //   dragSize: 'auto',
    // },
    // on: {
    //   slideChange: function (sw) {
    //     $('.box-banner .js-current').text(bannerslider.activeIndex+1);
    //   }
    // }
  });
}



$('.js-nav-btn').on('click',function(){	
  var $this = $(this);
  var navBlock = $('.js-menu-wrap');
  $this.toggleClass('burger_active');
  if ($this.hasClass('burger_active')) {
      navBlock.animate({"left":"0px"});	
    } else {
      navBlock.animate({"left":"-100%"});	
    } 

});


if($('.home-menu').length) {
  $(".js-menu-anchor a").click(function (e) {
    if($(window).width()<=992){
      var navBlock = $('.js-menu-wrap');
      navBlock.animate({"left":"-100%"});	
      $('.js-nav-btn').removeClass('burger_active');
    }
    
    e.preventDefault();
    var _href = $(this).attr("href").split('#').pop();	
    console.log(_href);
    $("html, body").animate({
      scrollTop: ($("#"+_href).offset().top-100) + "px"
    });
    return false;
  });
} 

$(window).scroll(function(){
  var aTop = 0;
  var navblock= $('header'); 
  if($(this).scrollTop()>aTop){
    navblock.addClass('fixed');
  } else{
    navblock.removeClass('fixed');
  }
});

$(".js-anchor").click(function () {
  var _href = $(this).attr("href");
  $("html, body").animate({
    scrollTop: ($(_href).offset().top-100) + "px"
  });
  return false;
});

// Fancybox.bind("[data-fancybox]", {});
// console.log(Fancybox);



$('.js-portfolio-btn').on("click",function(e){

  e.preventDefault();

  $('.js-portfolio-btn').removeClass("active");

  $(this).addClass("active");

  let term_id= $(this).data('term_id'); 

  
  let data={
    'term_id': term_id, 
    'action': 'portfolio_filter',
    'count': $(this).data('count'),
  };

  
  $('.js-load-before').attr("data-paged",1);
  $('.js-load-before').attr("data-term_id",term_id);



  $.ajax({
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    data: data, 
    error: function (jqXHR, exception) {
    },
    beforeSend : function ( xhr ) {		
    
    },
    success : function( data ){   
      console.log(data);
      if (data) {
        jQuery('.portfolio-items').html(data);        
      } else { }
    }
  });

});


document.addEventListener( 'wpcf7mailsent', function( event ) {

  let $popup = $('.js-popup');
  const $elem = $('.js-popup[data-block="thank"]');
  $popup.removeClass('is-show');
  $elem.addClass('is-show');
  setTimeout(function(){
    $popup.removeClass('is-show');
  }, 5000);
}, false );



$(window).scroll(function(){

  var bottomOffset = 2000; 

  var paged = $('.js-load-before').data( 'paged' );

  var maxPages = $('.js-load-before').data( 'maxpages' );

  var term_id = $('.js-load-before').data( 'term_id' );

  console.log(paged);
  console.log(maxPages);
  console.log(term_id);

  if( $(document).scrollTop() > ($(document).height() - bottomOffset) && !$('body').hasClass('loading')){
    if(paged<=maxPages) {
            $.ajax({
              type : 'POST',
              url: '/wp-admin/admin-ajax.php',
              data : {
                paged : paged,
                maxpages: maxPages,
                term_id: term_id,
                action : 'loadmore' 
              },
              beforeSend: function( xhr){
                $('body').addClass('loading');
              },
              success:function(data){
                console.log(data);
                if( data ) {
                  paged++;
                  
                  setTimeout(function(){ 
                    $('.portfolio-items').append( data );     
                    $('.js-load-before').data("paged",paged);   
                    $('body').removeClass('loading');         
                  },1000)
                
                 
                }
              }
            });
    }
  }

  });


});